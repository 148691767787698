.select-div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: .5rem;
    padding: 1.5rem;
    width: 100%;
    
    
    background-color: black;
}

.choose-div {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

label {
    color: #ff9900;
    
}

select { 
    padding: 1rem;
    margin-top: .5rem;
}

.select-button {
    padding: 1rem;
    width: 150px;
    background-color: #ff9900;
    color: white;
    border: 2px solid black;
    border-radius: .5rem;
    /* align-self: flex-end; */
}

.list-content{
    display: flex; 
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    
}

.list-content ul {
    list-style: none;
    /* display: flex; */
    display: grid;
    gap: .5rem;
    align-items: center;
    border: 2px solid white;
   font-size:  18px;
    width: 100%;
    padding: 1rem;
}

.list-content ul li span {
    color: #ff9900;
}



@media screen and (min-width: 800px){
    .list-content ul{
        display: flex;
        width: 50%;
    }
}