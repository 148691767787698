/* .carousel {
  position: absolute;
} */

@media screen and (max-width: 700px) {
  .carousel-img {
    max-width: 100%;
  }
}

.control-dots {
  display: none;
}
