.div-WrapperReservation {
  flex-grow: 1;
  background-color: black;
  // background: linear-gradient(90deg, rgb(46 40 94), rgb(49, 190, 200));
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  h3 {
    padding: 7px 0;
  }
  .tour-title {
    padding: 10px 0;
    span {
      color: red;
    }
  }

  section {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    border: white solid 4px;
    background: black;
    color: #fff;
    display: flex;
    flex-direction: column;
    // width: 82vw;
    @media screen and (min-width: 600px) {
      width: 400px;
    }
    span {
      color: red;
    }
    h2 {
      padding: 10px 0;
    }
    h6 {
      padding: 5px 0;
    }
    .radio-group {
      margin-bottom: 10px;
    }
    label {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      color: #000000;
      margin-top: 5px;
      padding: 5px;
      font-size: x-large;
      cursor: pointer;
      input[type="radio"] {
        accent-color: #03222e;
      }
    }
  }
}
.form-field {
  background-color: white;
  padding: 2px;
}
.error-handle {
  font-size: 18px;
  color: red;
  height: 10px;
  padding: 5px;
  // min-width: 80vw;
}
// .submit-btn {
//   margin-top: 10px;
//   background: linear-gradient(
//     90deg,
//     rgba(0, 0, 0, 1) 0%,
//     rgba(0, 0, 0, 1) 50%,
//     rgba(31, 30, 142, 1) 100%
//   );
//   color: #fff;
//   padding: 5px;
//   cursor: pointer;
// }
.joke {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin-top: 0 !important;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgb(0, 0, 0) 100%
  );
}

.dateWrapper {
  overflow: hidden;
  width: 100%;
  padding-inline: 8%;
  > div {
    margin: 0 auto;
    padding: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 600px) {
      // width: 400px;
    }
    div {
      width: 80%;
      margin-block: 2px;
      // padding: 1px 2px;
      text-transform: uppercase;
      border: 1px solid;
      color: #000000;
      cursor: pointer;
    }
    .selected {
      font-weight: bold;
      color: #fff;
    }
    p {
      text-align: center;
      font-size: 1.2em;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
