.div-wrapper-login {
  flex-grow: 1;
  background: black;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 100%;
      display: block;
      margin: 0 auto;
      margin-top: 10px;
    }
    input {
      margin-top: 15px;
      padding: 5px;
    }
    h1 {
      color: #fff;
      text-align: center;
    }
  }
}
