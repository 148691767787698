.div-admin-res {
  height: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  h3 {
    padding: 7px 0;
  }
  section {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    background: rgb(176, 227, 231);
    background: black;
    color: #fff;
    display: flex;
    flex-direction: column;
    span {
      color: red;
    }
    h4 {
      padding: 10px 0;
    }

    label {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      color: #000000;
      margin-top: 5px;
      padding: 5px;
      text-transform: uppercase;
      font-size: 15px;
      cursor: pointer;
      input[type="radio"] {
        accent-color: #03222e;
      }
    }
  }
}
.form-field {
  background-color: white;
  padding: 2px;
}
.error-handle {
  font-size: 18px;
  color: red;
  height: 18px;
  padding: 5px;
  // min-width: 80vw;
}
.submit-btn {
  margin-top: 10px;
  font-size: 25px;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.joke {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  margin-top: 0 !important;
  background: black;
}
.plus-minus {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
  button {
    width: 30px;
    font-size: 20px;
  }
}

.time-picker-div{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  div{
    display: flex;
    flex-direction: column;
    button{
      padding: 2px 5px;
      margin-bottom: 2px;
    }
  }
  p{
    color: white;
  }
  input{
    text-align: center;
    width: 25px;
    font-size: 16px;
  }
}
.clock-inputs {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  gap: 10px;

  label {
    background-color: #000000;
    color: #fff;
   
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}