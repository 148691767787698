.profile-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1;
  background-color: black;
}
.profile-card {
  flex: 1;
  color: white;
  padding: 1rem;
  flex-direction: column;
  display: flex;
  justify-content: center;

  gap: 2rem;
}

.profile-span {
  color: orange;
}

.profile-card-content {
  border: 3px solid white;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  border-radius: 0.5rem;
}

h1 {
  color: white;
  text-align: center;
  padding: 1rem;
}
