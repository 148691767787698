:root {--height:50px}


.boat-toggle-div, .past-toggle-div{
  margin: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
  button {
    border: none;
    padding: 15px;
    color: rgb(46 40 94);
    background-color: rgb(49 190 200);
    &.selected{
      background-color: white;
      box-shadow: 0 0 10px 0 white;
      color: rgb(46 40 94);
    }
  }
}
.past-toggle-div button{
  border: none;
  padding: 15px;
  color: white;
  background: rgb(46 40 94);
  &.selected{
    background-color: white;
    box-shadow: 0 0 10px 0 white;
    color: rgb(46 40 94);
  }  
}
.div-admin-tours {
  color: #fff;
  main {
    margin-inline: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc((var(--height) + 10px)*5);
    // display: flex;
    // flex-direction: column;
    // gap: 10px;
    overflow-y: scroll;
    white-space: nowrap;
    section:first-of-type{
      margin-top: 0;
    }
    section {
      width: 100%;
      min-height: var(--height);
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      padding: 5px 20px;
      margin: 5px 0;
      gap: 5px;
      background: black;
      background-color: rgb(49 190 200);
      color: rgb(46 40 94);
      p {
        font-size: 0.9em;
      }
      button {
        padding: 5px;
        background-color: rgb(46 40 94);
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
      .del{
        background-color: #721717;
      }
    }
  }
}
