.successModal{
    min-width: 100vw;
    min-height: 100vh;
    position: fixed;
    z-index: "modal";
    top:0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    width: 60%;
    .modal-content-red{
        background-color: red;
        border-radius: 10px;
        padding: 20px;
        font-weight: 600;
        color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        p {
            font-size: 20px;
            margin-bottom: 15px;
        }
        a {
            color: black;
        }
        button {
            margin-top: 15px;
            padding: 5px;
            cursor: pointer;
            border: 1px solid darkblue;
            border-radius: 5px;
            font-weight: 600;
        }
        
    }
}