/* .div-header {
  height: 8vh;
  background: linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgb(0, 0, 0) 50%,
    rgb(31, 30, 142) 100%
  );
  box-shadow: 0px 0px 19px -3px rgb(29, 29, 107);
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.div-header a {
  text-decoration: none;
  color: #fff;
}
.div-header nav {
  display: flex;
  gap: 15px;
}
.div-header button {
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px;
  width: 75px;
  cursor: pointer;
}
.div-header .log-out {
  background-color: #721717;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px;
  width: 75px;
  cursor: pointer;
}
.div-header img {
  width: 70px; */
/* } # sourceMappingURL=header.css.map */
/* .profile-name {
  padding-left: 16px;
} */
@media screen and (min-width: 700px) {
  .profile-name {
    font-size: 26px;
  }
}
