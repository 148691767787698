.div-choose-boat {
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    padding: 10px;
  }
  p {
    padding: 5px;
    span {
      margin-left: 4px;
      text-transform: uppercase;
     background-image: linear-gradient(
       -225deg,
       #ffffff 0%,
       #ffffff 29%,
       #000000 67%,
       #000000 100%
     );
     background-size: auto auto;
     background-clip: border-box;
     background-size: 200% auto;
     color: #fff;
     background-clip: text;
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     animation: textclip 2s linear infinite;
     display: inline-block;
         font-size: 18px;
   }
   
   @keyframes textclip {
     to {
       background-position: 200% center;
     }
   }
  }
}
.pointer{
  cursor: pointer;
}
.choose-boat {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
  justify-content: center;
  padding: 25px;
  img {
    width: 100%;
    // border-radius: 10%;
    // border: 2px solid white;
  }
  .card-and-buttons{
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
  }
  .card{
    border: white solid 4px;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    h2{
      font-size: medium;
      text-align: center;
      text-transform: uppercase;
      padding: 7px;
      font-family: 'Randome', sans-serif;
      padding-bottom: 0;
    }
    h3{
      font-size: medium;
      text-align: center;
      font-family: 'Randome', sans-serif;
      padding-top: 0;
    }
    hr{
      height: 4px;
      background-color: #fff;
    }
    .tour-desc{
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      div{
        display: flex;
        width: 50%;
        align-items: center;
        min-height: 50px;
        img{
          width: 30%;
        }
        p{
          text-align: center;
          height: fit-content;
          margin: 0 auto;
          font-family: 'Gagalin';

        }
      }
    }
  }
  @media screen and (min-width: 600px) {
    .card-and-buttons{
      width: 30%;
      overflow: hidden;
    }
    .card{
      width: 100%;
    }
  }
}
.marquee-parent {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 90px;
  padding-top: 10px;
}
.marquee-child {
  display: block;
  font-size: xxx-large;
  width: 8000px;
  /* width of your text div */
  height: 90px;
  /* height of your text div */
  position: absolute;
  animation: marquee 90s linear infinite; /* change 5s value to your desired speed */
}
.marquee-child:hover {
  animation-play-state: paused;
  cursor: pointer;
}
@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -8000px /* same as your text width */
  }
}

.fi-eu {
  background-image: url(../../../public/icons/eu.svg);
}
.fi-rs {
  background-image: url(../../../public/icons/rs.svg);
}
.fi-non {
  display: inline-block;
  line-height: 1em;
  position: relative;
  width: 1.33333333em;
  background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
}