.div-modal-tour {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  button {
    width: 100%;
  }
  .modal-container {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgb(46 40 94) 100%,
      rgb(49, 190, 200) 100%
    );
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    main {
      position: relative;
      button {
        background-color: #fff;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        position: absolute;
        top: -21px;
        right: -21px;
      }
    }
    section {
      p {
        font-size: 12px;
      }
      text-transform: uppercase;
      .seats-boat {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 5px;
      }
      .reservation-passengers {
        margin-top: 20px;
        height: 385px;
        overflow: scroll;
        background-color: black;
        border-radius: 10px;
        padding: 10px;

        .reservation-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 10px;
          border: 2px solid #fff;
          padding: 5px;
          div {
            padding-top: 5px;
          }
          div :first-of-type {
            padding: 0;
          }
          h5 {
            padding-top: 5px;
          }

          button {
            margin-top: 5px;
            background-color: #721717;
            color: #fff;
            cursor: pointer;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
.modal-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  border: 1px solid #fff;
  margin-top: 5px;
  padding: 5px;
}
.user-email {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}
