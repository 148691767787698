/* src/VideoPlayer.css */

.video-container {
    position: relative;
    width: 360px; /* Adjust width to resemble a mobile device */
    height: 640px; /* Adjust height to resemble a mobile device */
    border: 16px solid black;
    border-radius: 36px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin: 0 auto;
  }
  
  .video-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
  