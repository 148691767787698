.div-create-account {
  background: black;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 20px 0;

    button {
      width: 100%;
      display: block;
      margin: 0 auto;
      margin-top: 10px;
    }
    input {
      margin-top: 15px;
      width: 100%;
      font-size: 20px;
      padding: 5px;
    }
    h1 {
      color: #fff;
      text-align: center;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
}

.success-create {
  color: rgb(0, 0, 0);
  font-size: 12px;
  background-color: #03ffaf;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  width: 175px;
}
