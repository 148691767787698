@media screen and (min-width: 700px) {
  .loader {
    width: 10%;
  }
}

/* .loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  height: 84vh;
  font-size: 30px;
  color: #0000;
  background: linear-gradient(90deg, #c02942 calc(50% + 0.5ch), #000 0)
    right/calc(200% + 1ch) 100%;
  -webkit-background-clip: text;
  background-clip: text;
  animation: l7 2s infinite steps(11);
}
.loader:before {
  content: "Loading...";
  text-align: center;
}
@keyframes l7 {
  to {
    background-position: left;
  }
} */
.loader {
  width: 30%;
  text-align: center;
  font-size: 38px;
  color: #fff;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  position: relative;
  font-family: "Gagalin";
}
.loader:after {
  content: "";

  height: 4px;
  width: 0%;
  display: block;
  background: #ff9900;
  animation: 2s lineGrow linear infinite;
}

@keyframes lineGrow {
  to {
    width: 100%;
  }
}
